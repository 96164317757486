.contact-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 50px;
}

.contact-container h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.contact-container p {
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: #555;
}

.contachheading {  
  
  font-size: 2.3rem; /* Decreased heading size slightly */
  margin-bottom: 20px;
  text-align: left;
  color: #333; /* Darker color for headings */
  border-bottom: 2px solid #007bff; /* Blue underline for emphasis */
  padding-bottom: 10px; /* Spacing below the heading */
  text-align: center;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

label {
  margin-bottom: 10px;
  font-size: 1.1rem;
  color: #333;
}

input,
textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

/* Responsive Design */

/* Tablet and smaller screens */
@media (max-width: 768px) {
  .contact-container {
      padding: 40px 15px;
  }

  .contact-container h1 {
      font-size: 2rem; /* Slightly smaller heading for tablets */
  }

  .contact-container p {
      font-size: 1rem; /* Adjust paragraph size */
  }

  input,
  textarea {
      font-size: 1rem; /* Slightly smaller input fields */
  }

  button {
      padding: 10px 15px; /* Adjust button size */
  }
}

/* Mobile and smaller screens */
@media (max-width: 480px) {
  .contact-container {
      padding: 30px 10px;
  }

  .contact-container h1 {
      font-size: 1.8rem; /* Smaller heading for mobile devices */
  }

  .contact-container p {
      font-size: 0.9rem; /* Adjust paragraph font size */
  }

  input,
  textarea {
      font-size: 0.9rem; /* Adjust input field size */
  }

  button {
      padding: 8px 12px; /* Smaller button size */
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  box-sizing: border-box;
}

/* Ensuring proper font size scaling for all screens */
html {
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Responsive font sizing */
@media (max-width: 1200px) {
  html {
      font-size: 15px; /* Slightly reduced font size for large tablets and small desktops */
  }
}

@media (max-width: 768px) {
  html {
      font-size: 14px; /* Smaller font for tablets */
  }
}

@media (max-width: 480px) {
  html {
      font-size: 13px; /* Even smaller font for mobile devices */
  }
}

/* Additional responsive adjustments for better mobile experience */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px; /* Adding some padding for better spacing on smaller screens */
}

@media (max-width: 768px) {
  .container {
      padding: 0 15px; /* Reduce padding on tablets */
  }
}

@media (max-width: 480px) {
  .container {
      padding: 0 10px; /* Minimal padding for mobile devices */
  }
}

/* Ensuring buttons, inputs, and other form elements are responsive */
button, input, textarea {
  width: 100%; /* Full width on small devices */
  max-width: 100%; /* Prevent overflow on larger screens */
}

@media (min-width: 768px) {
  button, input, textarea {
    width: auto; /* Return to default width for tablets and above */
  }
}

/* Responsive images */
img {
  max-width: 100%;
  height: auto;
  display: block;
}

/* SecurityPolicy.css */

.security-policy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 50px;
  }
  
  .security-policy-card {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 50px;
  }
  
  .security-policy-title {
    font-size: 2.3rem; /* Decreased heading size slightly */
    margin-bottom: 20px;
    text-align: left;
    color: #333; /* Darker color for headings */
    border-bottom: 2px solid #007bff; /* Blue underline for emphasis */
    padding-bottom: 10px; /* Spacing below the heading */
    text-align: center;
  }
  
  .security-policy-subheading {
    font-family: 'Poppins', sans-serif;
    font-size: 1.25rem;
    color: #34495e;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .security-policy-text {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    color: #7f8c8d;
    line-height: 1.8;
    margin-bottom: 20px; /* Adds space after paragraphs */
    text-align: justify; /* Justifies text for cleaner alignment */
  }
  
  .security-policy-list {
    padding-left: 20px; /* Adds padding for indentation */
    margin-top: 10px;
    margin-bottom: 20px; /* Adds space after the list */
  }
  
  .security-policy-list li {
    font-family: 'Roboto', sans-serif;
    color: #7f8c8d;
    font-size: 1rem;
    margin-bottom: 10px;
  }
  
  .security-policy-list li::marker {
    color: #34495e; /* Custom bullet color for lists */
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .security-policy-card {
      padding: 20px; /* Reduce padding for smaller screens */
    }
  
    .security-policy-title {
      font-size: 1.75rem; /* Adjust font size */
    }
  
    .security-policy-subheading {
      font-size: 1.1rem; /* Adjust font size */
    }
  
    .security-policy-text,
    .security-policy-list li {
      font-size: 0.95rem; /* Adjust font size for readability */
    }
  }
  
  @media (max-width: 480px) {
    .security-policy-title {
      font-size: 1.5rem; /* Further reduce font size */
    }
  
    .security-policy-subheading {
      font-size: 1rem; /* Further reduce font size */
    }
  
    .security-policy-text,
    .security-policy-list li {
      font-size: 0.85rem; /* Further reduce font size */
    }
  
    .security-policy-list {
      padding-left: 10px; /* Reduce padding for smaller screens */
    }
  }
  
.terms-service-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 50px;
}

.terms-service-title {
    font-size: 2.3rem;
    margin-bottom: 20px;
    color: #333;
    border-bottom: 2px solid #007bff;
    padding-bottom: 10px;
    text-align: center;
}

.terms-service-subheading {
    font-family: 'Poppins', sans-serif;
    font-size: 1.25rem;
    color: #34495e;
    margin-top: 20px;
    margin-bottom: 10px;
}

.terms-service-text {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    color: #7f8c8d;
    line-height: 1.8;
    margin-bottom: 20px;
    text-align: justify;
}

@media (max-width: 768px) {
    .terms-service-title {
      font-size: 1.75rem;
    }

    .terms-service-subheading {
      font-size: 1.1rem;
    }

    .terms-service-text {
      font-size: 0.95rem;
    }
}

@media (max-width: 480px) {
    .terms-service-title {
      font-size: 1.5rem;
    }

    .terms-service-subheading {
      font-size: 1rem;
    }

    .terms-service-text {
      font-size: 0.85rem;
    }
}

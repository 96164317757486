/* Footer.css */
.footer {
  background-color: #333;
  color: #fff;
  padding: 0 0 15px 0;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; /* Allows wrapping for smaller screens */
}

.logo-text {
  font-size: 1.5rem;
}

.footer-section {
  flex: 1;
  margin-bottom: 20px;
  /* padding: 10px; */
}

.footer-section.about {
  max-width: 300px;
}

.contact span {
  display: block;
  margin-bottom: 10px;
}

.socials a {
  color: #fff;
  font-size: 1.2rem;
  margin-right: 10px;
}

.links ul {
  list-style: none;
  padding: 0;
}

.links li {
  margin-bottom: 10px;
}

.footer-bottom-text {
  text-align: center;
  margin-top: 20px;
  color: #ccc;
}

/* Responsive styles */
@media (max-width: 768px) {
  .footer-content {
      flex-direction: column;
      align-items: center;
  }

  .footer-section {
      max-width: 100%;
      text-align: center;
  }

  .footer-section.about {
      max-width: 100%;
  }

  .socials a {
      margin-right: 5px;
  }
}

@media (max-width: 480px) {
  .footer {
      /* padding: 20px 0; */
  }

  .footer-content {
      /* padding: 0 20px; */
  }

  .logo-text {
      font-size: 1.2rem;
  }

  .footer-section {
      /* padding: 5px; */
  }

  .footer-bottom-text {
      font-size: 0.9rem;
  }
}

/* General About Us section */
.about-us {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 30px;
  background-color: #f9f9f9;
  width: 100%; /* Ensures card takes full width */
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 50px;
  padding-bottom: 60px; /* Added bottom padding to the section */
  box-sizing: border-box; /* Prevents padding from adding extra width */
  overflow-x: hidden; /* Prevents horizontal scroll on small screens */
}

/* About Content */
.about-content {
  margin: 20px;
  box-sizing: border-box; /* Ensures margins and padding don't overflow */
}

/* Heading styles */
.about-heading {
  font-size: 2.3rem; /* Decreased heading size slightly */
  margin-bottom: 20px;
  text-align: left;
  color: #333; /* Darker color for headings */
  border-bottom: 2px solid #007bff; /* Blue underline for emphasis */
  padding-bottom: 10px; /* Spacing below the heading */
  text-align: center;
}

.about-subheading {
  font-size: 1.9rem; /* Slightly decreased subheading size */
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: left;
  color: #007bff; /* Blue color for subheadings */
}

/* Text content */
.about-text {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 20px;
  color: #555; /* Slightly lighter text color for better readability */
  word-wrap: break-word; /* Ensure long text wraps correctly */
}

/* List styling */
.about-list {
  margin: 0;
  padding-left: 20px;
  text-align: left;
}

.about-list-item {
  margin-bottom: 10px;
  position: relative; /* For bullet customization */
}

/* Custom bullets for list items */
.about-list-item::before {
  content: "✔"; /* Check mark as a custom bullet */
  color: #007bff; /* Match bullet color with subheading */
  position: absolute;
  left: -25px; /* Positioning of custom bullet */
}

/* Responsive Design */
@media (max-width: 768px) {
  .about-us {
    padding: 20px; /* Adjust padding for smaller screens */
    width: 100%;
  }

  .about-content {
    margin: 15px;
    padding: 0; /* Remove excess padding */
  }

  .about-heading {
    font-size: 2rem; /* Decrease font size for smaller screens */
    text-align: center; /* Center heading text */
  }

  .about-subheading {
    font-size: 1.8rem; /* Slightly smaller for mobile */
    text-align: center; /* Center subheading text */
  }

  .about-text {
    font-size: 1rem; /* Reduce text size for readability on mobile */
    line-height: 1.5;
    text-align: justify; /* Justify text for better readability on mobile */
  }

  .about-list {
    padding-left: 10px; /* Adjust padding for smaller screens */
  }

  .about-list-item {
    font-size: 1rem; /* Adjust font size for list items */
  }
}

@media (max-width: 480px) {
  .about-us {
    padding: 10px; /* Reduce padding for very small screens */
  }

  .about-heading {
    font-size: 1.8rem; /* Further reduce heading size on very small screens */
  }

  .about-subheading {
    font-size: 1.5rem;
  }

  .about-text {
    font-size: 0.9rem; /* Slightly smaller text for very small screens */
  }

  .about-list-item {
    font-size: 0.9rem; /* Smaller list item font for small screens */
  }
}

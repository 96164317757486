/* Nav styles */
nav {
  background-color: #333;
  padding: 10px 0;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: center;
}

li {
  display: inline-block;
  margin-right: 20px;
  position: relative; /* Make each <li> the reference for its dropdowns */
}

li:last-child {
  margin-right: 0;
}

a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 16px;
  padding: 5px 10px;
  border-radius: 5px;
}

a:hover {
  background-color: #555;
}

/* Dropdown styles */
.dropdown-container {
  position: relative;
  display: inline-block; /* Keep it inline with other nav items */
  padding: 10px 20px;
}

.dropdown-container > a::after {
  content: '▼'; /* Small arrow icon for dropdowns */
  font-size: 12px;
  margin-left: 5px;
  color: white;
  vertical-align: middle;
}

/* Specific subjects that have sub-dropdown */
.dropdown-item.has-subcategory::after {
  content: '►'; /* Right arrow for subcategories */
  font-size: 12px;
  margin-left: 10px;
  color: #333;
  vertical-align: middle;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  z-index: 100;
  width: max-content;
  display: none; /* Initially hidden */
}

.dropdown-container:hover .dropdown {
  display: block; /* Show dropdown on hover */
}

.dropdown-item {
  display: block;
  padding: 10px 20px;
  cursor: pointer;
  color: #333;
  white-space: nowrap;
  transition: background-color 0.3s;
  text-decoration: none;
  text-align: left;
}

.dropdown-item:hover {
  background-color: #f5f5f5;
}

.dropdown-item:last-child {
  border-bottom: none;
}

/* Subcategory dropdown styles */
.subcategory-dropdown {
  position: absolute;
  top: 0;
  left: 100%; /* Align to the right of the parent dropdown */
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  z-index: 101;
  width: max-content;
  display: none; /* Initially hidden */
  min-width: 200px;
  text-align: left;
  max-height: 300px; /* Limit height for scrolling */
  overflow-y: auto; /* Make subcategory dropdown scrollable */
}

.dropdown-item:hover .subcategory-dropdown {
  display: block;
}

.subcategory-dropdown:hover {
  display: block;
}

/* Add scrollbar styling if needed */
.subcategory-dropdown::-webkit-scrollbar {
  width: 8px;
}

.subcategory-dropdown::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.subcategory-dropdown::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  nav ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }

  li {
    width: 33.33%; /* Show three items per row */
    margin-bottom: 10px;
  }

  .dropdown-container {
    padding: 10px;
  }

  /* For mobile, dropdown should open on click, not hover */
  .dropdown-container .dropdown,
  .dropdown-container .subcategory-dropdown {
    display: none; /* Hide dropdowns by default on mobile */
  }

  .dropdown-container.active .dropdown {
    display: block; /* Show dropdown when active */
  }

  .dropdown-item.active .subcategory-dropdown {
    display: block; /* Show subcategory dropdown when active */
  }

  .dropdown-item {
    padding: 15px; /* Increase padding for better touch targets */
  }

  .toggle-button {
    display: block; /* Ensure toggle button is visible on mobile */
    background-color: #333;
    color: #fff;
    padding: 10px;
    border: none;
    cursor: pointer;
  }

  .subcategory-dropdown {
    width: 100%; /* Ensure dropdown is full width on mobile */
    position: static; /* Change position for mobile display */
    max-height: none; /* Remove height restriction on mobile */
    overflow-y: visible;
  }
}

/* Pagination styles for small screens */
@media (max-width: 768px) {
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    flex-wrap: wrap; /* Ensure horizontal pagination wraps on small screens */
  }

  .pagination li {
    margin-right: 10px;
    margin-bottom: 5px; /* Add some space between pagination items */
    width: auto;
  }

  .pagination li:last-child {
    margin-right: 0;
  }

  .pagination a {
    padding: 10px 15px;
    background-color: #333;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-size: 14px;
  }

  .pagination a:hover {
    background-color: #555;
  }
}

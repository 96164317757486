.mcq-submit {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 30px;
  background-color: #f9f9f9;
  width: 100%; /* Ensures card takes full width */
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 20px;
  padding-bottom: 60px; /* Added bottom padding to the section */
}

.submitheading {
  font-size: 2.3rem; /* Decreased heading size slightly */
  margin-bottom: 20px;
  text-align: left;
  color: #333; /* Darker color for headings */
  border-bottom: 2px solid #007bff; /* Blue underline for emphasis */
  padding-bottom: 10px; /* Spacing below the heading */
  text-align: center;

}

/* Heading styling */
.mcq-submit h2 {
  margin-bottom: 20px;
  font-size: 2rem; /* Responsive font size */
}

/* Form group styling */
.form-group {
  margin-bottom: 15px;
}

/* Label styling */
.form-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 1.1rem; /* Responsive label size */
}

/* Input field styling */
.form-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box; /* Ensure padding doesn't overflow */
}

/* Button styling */
button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

/* Error and success messages styling */
.error, .success {
  font-size: 1rem;
}

.error {
  color: red;
}

.success {
  color: green;
}

/* Responsive media queries */
@media (max-width: 768px) {
  .mcq-submit {
      padding: 15px; /* Reduced padding for tablet devices */
  }

  .mcq-submit h2 {
      font-size: 1.75rem; /* Smaller heading for tablets */
  }

  .form-group label {
      font-size: 1rem; /* Adjusted label size for tablets */
  }

  .form-group input {
      padding: 10px; /* Slightly increased padding for better touch on tablets */
      font-size: 0.95rem;
  }

  button {
      padding: 8px 16px; /* Reduced button size for tablets */
      font-size: 15px;
  }
}

@media (max-width: 480px) {
  .mcq-submit {
      padding: 10px; /* Further reduced padding for mobile devices */
  }

  .mcq-submit h2 {
      font-size: 1.5rem; /* Smaller heading for mobile */
  }

  .form-group label {
      font-size: 0.9rem; /* Smaller label size for mobile */
  }

  .form-group input {
      padding: 8px; /* Adjusted padding for mobile */
      font-size: 0.9rem;
  }

  button {
      padding: 7px 14px; /* Reduced button size for mobile */
      font-size: 14px;
  }
}

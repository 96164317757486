.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  gap: 16px;
}

.pagination-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination-button:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}

.pagination-button:hover:not(:disabled) {
  background-color: #2980b9;
}

.pagination-info {
  font-size: 18px;
  font-weight: 500;
  color: #2c3e50;
}

.page-container {
  display: flex;
  flex-direction: column;
  align-items: center !important;
  justify-content: center !important;
  min-height: 100vh; /* Ensure it takes full viewport height */
  padding: 50px;
}

.pagination-container {
  display: flex;
  justify-content: center !important;
  margin-top: 70px; /* Adjust this value if needed */
  margin-bottom: 100px; /* Adjust this value if needed */
}

.pagination-stack {
  display: flex;
  justify-content: center !important;
  width: 100%;
}

.MuiPagination-root {
  margin: auto !important;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .pagination-button {
    padding: 8px 16px;
    font-size: 14px;
  }

  .pagination-info {
    font-size: 16px;
  }

  .page-container {
    padding: 30px;
  }

  .pagination-container {
    margin-top: 50px;
    margin-bottom: 80px;
  }
}

@media (max-width: 480px) {
  .pagination-button {
    padding: 6px 12px;
    font-size: 12px;
  }

  .pagination-info {
    font-size: 14px;
  }

  .page-container {
    padding: 20px;
  }

  .pagination-container {
    margin-top: 40px;
    margin-bottom: 60px;
  }

  .MuiPagination-root {
    width: 90%;
  }
}
